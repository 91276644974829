<template>
  <modal-wrapper
    :id="id"
    hide-footer
    size="md"
    :on-modal-hidden="resetData"
    :no-auto-close="feedbackCreated"
  >
    <template #title>
      <div class="modal-content--feedback__title">
        <img
          :src="require('../assets/img/feedback_icon.png')"
          class="modal-content--feedback__title__logo"
          alt=""
        />
        <span class="modal-content--feedback__title__content">Richiesta di feedback</span>
      </div>
    </template>
    <template #body>
      <div class="px-3 mt-2">
        <h5 class="heading--dark">Tipologia di feedback richiesto:</h5>
        <b-input-group class="mt-2">
          <div class="d-flex w-100">
            <div class="flex-grow-1">
              <v-select
                id="feedbackType"
                label="value"
                placeholder="Selezionare una voce"
                v-model="feedbackTypeModel"
                :options="feedbackTypes"
                :reduce="item => item.key"
                v-on:option:selected="loadQuestions"
              >
              </v-select>
            </div>
          </div>
        </b-input-group>
        <b-input-group class="my-3" v-if="isQuestionLoaded">
          <div class="d-flex w-100">
            <div class="flex-grow-1">
              <v-select
                id="feedbackQuestion"
                label="value"
                placeholder="Selezionare una voce"
                v-model="feedbackQuestionModel"
                :options="feedbackQuestions"
                :reduce="item => item.key"
              >
              </v-select>
            </div>
          </div>
        </b-input-group>
        <h5 class="heading--dark mb-2 mt-3">Note:</h5>
        <b-form-textarea
          id="notes"
          v-model="feedbackNoteModel"
          placeholder=""
          rows="6"
          max-rows="6"
        ></b-form-textarea>
        <div class="mt-3">
          <b-form-group
            class="custom-control custom-checkbox text-center"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="feedbackCheck"
              v-model="feedbackCheckModel"
            >
            <label class="custom-control-label" for="feedbackCheck">
              Consenti una sola risposta utilizzando il link generato
            </label>
          </b-form-group>
        </div>
        <div class="text-center mt-4" v-if="!feedbackCreated && !isGeneratingLink">
          <b-btn size="lg" variant="success" :disabled="submitDisable" @click="submitFeedbackRequest">GENERA COLLEGAMENTO</b-btn>
        </div>
        <div v-if="isGeneratingLink">
          <loader-info />
        </div>
      </div>
      <div class="px-3 mt-4 mx-n3 mb-n3 bg-gray-300" v-if="feedbackCreated">
        <div class="p-3">
          <h6><strong>CONDIVIDI QUESTO LINK PER RICHIEDERE IL FEEDBACK</strong></h6>
          <b-input-group class="mt-2 input--alt-style">
            <b-form-input type="text" size="lg" disabled :value="feedbackUri"></b-form-input>
            <b-input-group-append>
              <b-button
                variant="warning"
                @click="openModalShare"
                class="px-3"
              >
                <font-awesome-icon icon="share-alt"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <div
            class="text-right mt-2"
            v-if="uriCheck"
          >
            <b-btn v-clipboard="() => feedbackUri" @success="handleSuccess" @error="handleError" size="sm">COPIA LINK</b-btn>
          </div>
        </div>
      </div>
      <b-row class="mt-3 pt-3 border-top text-right" v-if="feedbackCreated">
        <b-col md="12">
          <b-btn size="sm" variant="primary" @click="closeModal">CHIUDI</b-btn>
        </b-col>
      </b-row>
      <modal-badge-share
        id="feedbackShare"
        :uri="feedbackUri"
        modalTitle="Invia richiesta feedback a"
        :title="shareTitle"
        :description="shareDescription"
        v-if="uriCheck"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import {
  extractErrorMessage,
  isNotEmpty, isNotExist,
  isPresent,
} from '@/utils/validators';
import { toastInformation } from '@/utils/toast';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const ModalBadgeShare = () => import('@/components/userSections/ModalBadgeShare.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'ModalFeedback',
  components: {
    ModalWrapper, ModalBadgeShare, LoaderInfo,
  },
  props: {
    id: String,
    onModalHide: Function,
  },
  created() {
    if (!this.$store.getters['tableUtils/hasFeedbackType'] && !this.$store.getters['tableUtils/isLoadingFeedbackType']) {
      this.$store.dispatch('tableUtils/loadFeedbackType', 'it').then(() => {
        console.log('loaded feedbackType');
      });
    }
  },
  mounted() {
    this.resetData();
  },
  data() {
    return {
      feedbackUri: null,
      feedbackTypeEnabled: true,
      feedbackTypeModel: null,
      feedbackQuestionModel: null,
      feedbackCheckModel: true,
      feedbackNoteModel: null,
      feedbackTypeDetailEnabled: true,
      feedbackCreated: false,
      isGeneratingLink: false,
      submitted: false,
      feedbackInfo: null,
    };
  },
  computed: {
    currentName() {
      return this.$store.getters['auth/currentName'];
    },
    shareTitle() {
      return `${this.currentName} ti ha richiesto di inviare un feedback`;
    },
    shareDescription() {
      return `Questo è il link da usare per fornire un feedback su ${this.currentName}`;
    },
    hasDataFeedback() {
      return this.$store.getters['tableUtils/hasFeedbackType'];
    },
    feedbackTypes() {
      return this.$store.getters['tableUtils/getFeedbackType'];
    },
    editable() {
      return true;
    },
    submitDisable() {
      if (this.feedbackCreated || this.submitted) {
        return true;
      }
      if (isNotExist(this.feedbackTypeModel)) {
        return true;
      }
      return isNotExist(this.feedbackQuestionModel);
    },
    feedbackQuestions() {
      if (isNotEmpty(this.feedbackTypeModel)) {
        return this.$store.getters['tableUtils/getTableList'](this.feedbackTypeModel) || [];
      }
      return [];
    },
    isQuestionLoaded() {
      // console.log('isQuestionLoaded 1', this.feedbackTypeModel);
      if (isNotEmpty(this.feedbackTypeModel)) {
        // console.log('isQuestionLoaded 2', this.$store.getters['tableUtils/isLoadingTableList'](this.feedbackTypeModel));
        if (this.$store.getters['tableUtils/isLoadingTableList'](this.feedbackTypeModel)) {
          return false;
        }
        // console.log('isQuestionLoaded', this.$store.getters['tableUtils/hasTableList'](this.feedbackTypeModel));
        return this.$store.getters['tableUtils/hasTableList'](this.feedbackTypeModel);
      }
      return false;
    },
    uriCheck() {
      return !!isPresent(this.feedbackUri);
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    resetData() {
      console.log('reset feedback data');
      this.feedbackUri = '';
      this.feedbackTypeEnabled = true;
      this.feedbackTypeModel = null;
      this.feedbackQuestionModel = null;
      this.feedbackCheckModel = true;
      this.feedbackNoteModel = null;
      this.feedbackTypeDetailEnabled = true;
      this.submitted = false;
      this.feedbackInfo = null;
      if (this.onModalHide && this.feedbackCreated) this.onModalHide();
      this.feedbackCreated = false;
    },
    loadQuestions() {
      console.log('loadQuestions', this.feedbackTypeModel);
      if (isNotEmpty(this.feedbackTypeModel)) {
        this.feedbackQuestionModel = null;
        if (!this.$store.getters['tableUtils/hasTableList'](this.feedbackTypeModel)) {
          if (!this.$store.getters['tableUtils/isLoadingTableList'](this.feedbackTypeModel)) {
            this.$store.dispatch('tableUtils/loadFeedbackResponses', {
              feedbackType: this.feedbackTypeModel,
              language: 'it',
            });
          }
        }
      }
    },
    submitFeedbackRequest() {
      this.submitted = true;
      const entityType = this.isCompanyProfile ? 'company' : 'person';
      const entityId = this.isCompanyProfile ? this.$store.getters['subject/currentCompany'].companyId : null;
      const request = {
        entityType,
        entityId,
        feedbackType: this.feedbackTypeModel,
        feedbackQuestion: this.feedbackQuestionModel,
        singleResponse: this.feedbackCheckModel,
        note: this.feedbackNoteModel,
      };
      console.log('submitFeedbackRequest', request);
      this.isGeneratingLink = true;
      this.$store.dispatch('score/createFeedbackRequest', request).then((response) => {
        this.submitted = false;
        this.feedbackInfo = response.data;
        this.feedbackUri = response.data;
        this.feedbackCreated = true;
        this.isGeneratingLink = false;
      },
      (error) => {
        this.message = extractErrorMessage(error);
        this.submitted = false;
        this.isGeneratingLink = false;
      });
    },
    handleSuccess(e) {
      console.log('copy data', e);
      toastInformation('Link feedback copiato nella clipboard');
    },
    handleError(e) {
      console.log('copy data error', e);
    },
    openModalShare() {
      this.$bvModal.show('feedbackShare');
    },
  },
};
</script>

<style scoped>

</style>
